import React, { useEffect, useState } from 'react';
import '../styles/content.css';
import { IoPrintOutline } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import '../styles/Papeleria.css';
import { getAllPrints } from '../services/productService';

const Papeleria = () => {
    const [prints, setPrints] = useState([]);

    useEffect(() => {
        const fetchPrints = async () => {
            try {
                const response = await getAllPrints();
                // Ordenar las impresiones por ID en orden descendente
                const sortedPrints = response.sort((a, b) => b.id - a.id);
                setPrints(sortedPrints);
            } catch (error) {
                console.error('Error fetching prints:', error);
            }
        };
        fetchPrints();
    }, []);

    // Función para limitar el nombre del documento
    const truncateDocumentName = (name, maxLength) => {
        return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
    };

    return (
        <div>
            <div className="card-container">
                <div className="card">
                    <h2>TABLA DE IMPRESIONES</h2>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Documento</th>
                                    <th>Tipo de Papel</th>
                                    <th>Color/Blanco Negro</th>
                                    <th>Formato Hoja</th>
                                    <th>Anillado</th>
                                    <th>Material Anillado</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prints.map(print => (
                                    <tr key={print.id}>
                                        {/* Documento: mostrar el nombre truncado */}
                                        <td>{truncateDocumentName(print.name, 20)}</td>

                                        {/* Tipo de Papel */}
                                        <td>{print.paperType}</td>

                                        {/* Color o Blanco y Negro */}
                                        <td>{print.color ? 'Color' : 'Blanco y Negro'}</td>

                                        {/* Formato de la Hoja */}
                                        <td>{print.sheetFormat}</td>

                                        {/* Anillado */}
                                        <td>{print.banding ? 'Sí' : 'No'}</td>

                                        {/* Material de Anillado */}
                                        <td>{print.bandingMaterial}</td>

                                        {/* Cantidad */}
                                        <td>{print.quantity}</td>

                                        {/* Precio */}
                                        <td>${print.price}</td>

                                        {/* Acciones: Enlace en el ícono de impresión */}
                                        <td>
                                            <span>
                                                <a 
                                                    href={`https://api-copyxpress.kaizensoftware.com.ec${print.document}`} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                >
                                                    <IoPrintOutline className="icon-pencil" />
                                                </a>
                                            </span>
                                            <span>
                                                <FaRegTrashCan className="icon-pencil-1" />
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Papeleria;
