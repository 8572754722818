import React, { useState, useEffect } from 'react';
import '../styles/Pedidos.css';
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { getAllOrders, updateOrderStatus } from '../services/productService'; // Servicio para actualizar el estado
import { showEditConfirmation, showSuccessAlert, showErrorAlert } from '../components/Alert'; // Importar alertas

const Pedidos = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderStatus, setOrderStatus] = useState('');
    const [originalStatus, setOriginalStatus] = useState('');

    const statusOptions = ["Pendiente", "Enviado", "Entregado", "Cancelado"];

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const data = await getAllOrders();

                // Ordenar en orden descendente por la fecha de la orden (orderDate)
                const sortedOrders = data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

                setOrders(sortedOrders); // Actualizamos el estado con los pedidos ordenados
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    const handleViewOrder = (order) => {
        // Establece el pedido seleccionado
        setSelectedOrder(order);

        // Cargar el estado del pedido en el combo box
        setOrderStatus(order.orderStatus); // Carga el estado por defecto desde la respuesta
        setOriginalStatus(order.orderStatus); // Guardamos el estado original
    };

    const handleStatusChange = async (e) => {
        const newStatus = e.target.value; // Valor seleccionado del combo box
        setOrderStatus(newStatus); // Actualizamos el estado en el componente

        // Solo cambiamos el estado si es diferente al original
        if (newStatus !== originalStatus) {
            showEditConfirmation(async () => {
                try {
                    console.log("Pedido ID:", selectedOrder.id);
                    console.log("Nuevo estado:", newStatus);

                    // Actualizamos el estado del pedido
                    await updateOrderStatus(selectedOrder.id, newStatus);
                    showSuccessAlert('El estado del pedido ha sido actualizado con éxito');
                    setOriginalStatus(newStatus); // Actualizamos el estado original después del cambio
                } catch (error) {
                    showErrorAlert('Hubo un error al actualizar el estado del pedido.');
                }
            });
        }
    };



    const closeCard = () => {
        setSelectedOrder(null); // Simplemente cierra la ventana sin realizar más acciones
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Pendiente': return 'orange';
            case 'Enviado': return '#008B8B';
            case 'Entregado': return 'green';
            case 'Cancelado': return 'red';
            default: return 'gray';
        }
    };

    return (
        <div>
            <div className="search-bar">
                <div className="search-input">
                    <input type="text" placeholder="Buscar..." />
                    <FaSearch className="search-icon" />
                </div>
            </div>
            <div className="card-container">
                <div className="card">
                    <h2>LISTADO DE PEDIDOS</h2>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Email</th>
                                    <th>Total</th>
                                    <th>Método de Pago</th>
                                    <th>Estado del Pedido</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.length > 0 ? (
                                    orders.map(order => (
                                        <tr key={order.id}>
                                            <td>{order.id}</td>
                                            <td>{order.buyerEmail}</td>
                                            <td>${order.total}</td>
                                            <td>{order.paymentMethod}</td>
                                            <td style={{ color: getStatusColor(order.orderStatus) }}>{order.orderStatus}</td>
                                            <td>
                                                <span
                                                    className="view-order-text"
                                                    onClick={() => handleViewOrder(order)}
                                                >
                                                ⬤ Ver pedido
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No hay pedidos disponibles</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {selectedOrder && (
                <div className="floating-card">
                    <div className="card-content">
                        <button className="close-button" onClick={closeCard}><IoMdClose /></button>
                        <h3>Detalles del Pedido #{selectedOrder.id}</h3>
                        <p><strong>Email del comprador:</strong> {selectedOrder.buyerEmail}</p>
                        <p><strong>Fecha del pedido:</strong> {new Date(selectedOrder.orderDate).toLocaleString()}</p>
                        <p><strong>Precio de envío:</strong> ${selectedOrder.shippingPrice}</p>
                        <p><strong>Subtotal:</strong> ${selectedOrder.subtotal}</p>
                        <p><strong>Total:</strong> ${selectedOrder.total}</p>
                        <p><strong>Método de pago:</strong> {selectedOrder.paymentMethod}</p>

                        {/* Combo box para cambiar el estado */}
                        <p>
                            <strong>Estado del pedido:</strong>
                            <select value={orderStatus} onChange={handleStatusChange}>
                                {statusOptions.map(status => (
                                    <option key={status} value={status}>{status}</option>
                                ))}
                            </select>
                        </p>

                        <p><strong>Dirección de envío:</strong>
                            {selectedOrder.shippingAddress.primary_street || 'No disponible'},
                            {selectedOrder.shippingAddress.secondary_street || 'No disponible'},
                            {selectedOrder.shippingAddress.city || 'No disponible'},
                            {selectedOrder.shippingAddress.state || 'No disponible'},
                            {selectedOrder.shippingAddress.country || 'No disponible'}
                        </p>

                        <h4>Items del Pedido:</h4>
                        <ul>
                            {selectedOrder.items.products.map(product => (
                                <li key={product.id}>
                                    {product.name} - ${product.price} x {product.quantity}
                                </li>
                            ))}
                            {selectedOrder.items.prints.map(print => (
                                <li key={print.id}>
                                    {print.name} - ${print.price} x {print.quantity}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pedidos;